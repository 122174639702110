
import Vue from 'vue';
import Component from 'vue-class-component';
import userModule from '@/store/modules/user/user.module';

import { tenantModule } from '@/store/modules/tenant/tenant.module';
import GuestCheckinModal from '@/modules/guests/components/GuestCheckinModal.vue';
import { mapState } from 'vuex';
import { guestModule } from '@/store/modules/guest.module';
import isEmpty from '@/app/utils/object.utils';
import guestProfileCard from '../components/GuestProfileCard.vue';
import guestProfileBookingCard from '../components/GuestProfileBookingsCard.vue';
import GuestProfileGuestCard from '../components/GuestProfileGuestCard.vue';
import profile from '../schema/guest-profile.schema';
import { Loading } from '../../auth/store/auth.service';
import { UserDto } from '../../../app/models/user/user.dto';
import { IGuestDto } from '../../../app/entities/tenant/IBookingDto';

@Component({
  components: {
    guestProfileCard,
    guestProfileBookingCard,
    GuestProfileGuestCard,
    GuestCheckinModal,
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('guest', ['guest']),
  },
})
export default class GuestLandingPage extends Vue {
  param = {
    id: 1,
  };

  async created(): Promise<void> {
    this.init();
  }

  @Loading
  async init() {
    if (isEmpty(guestModule.booking)) {
      // tenantModule.setTenantId(userModule.user?.profile.bookings.find((val: any) => val.isActive).tenantId);
      // await guestModule.findGuestBooking();
    }
  }

  active = true;

  get entityList(): any[] {
    return tenantModule.bookingMap;
  }

  get user() {
    return userModule.user;
  }

  get bookingDetails() {
    return guestModule.booking;
  }

  get guests() {
    const guestArray = guestModule?.booking?.guest || null;
    return guestArray?.filter((guest: Partial<any>) => {
      return !userModule?.user?.email?.includes(guest.email as string);
    });
  }

  get userSchema() {
    const { user } = userModule;
    return profile(user);
  }
}
