import { UserDto } from '../../../app/models/user/user.dto';

function profile(user: UserDto | null) {
  return [
    {
      component: 'dl',
      children: [
        {
          component: 'dt',
          children: 'Email:',
        },
        {
          component: 'dd',
          children: user?.email,
        },
        {
          component: 'dt',
          children: 'Contact:',
        },
        {
          component: 'dd',
          children: user?.mobilePhone,
        },
        {
          component: 'dt',
          children: 'Passport/ID:',
        },
        {
          component: 'dd',
          children: user?.profile?.passportId,
        },
        {
          component: 'dt',
          children: 'Insurance Provider:',
        },
        {
          component: 'dd',
          children: user?.profile?.insuranceProvider,
        },
        {
          component: 'dt',
          children: 'Insurance Number:',
        },
        {
          component: 'dd',
          children: user?.profile?.insuranceNumber,
        },
        {
          component: 'dt',
          children: 'Emergency Contact:',
        },
        {
          component: 'dd',
          children: user?.profile?.emergencyContact,
        },
        {
          component: 'dt',
          children: 'CoVID Status:',
        },
        {
          component: 'dd',
          children: [
            {
              component: 'div',
              class: 'inline-block bg-teal-200 text-green-600 covid-status',
              children: 'Approved',
            },
          ],
        },
      ],
    },
  ];
}

export default profile;
