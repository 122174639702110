
import { format } from 'path';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { guestModule } from '@/store/modules/guest.module';

@Component({})
export default class GuestProfilebookingCard extends Vue {
  dateOne = '2020-11-04';

  dateTwo = '2020-11-09';

  get booking() {
    return guestModule?.booking || null;
  }

  formatDates(dateOne: any, _dateTwo: any) {
    let formattedDates = '';
    if (dateOne) {
      formattedDates = format(dateOne);
    }
    // if (dateTwo) {
    //   formattedDates += ' - ' + format(dateTwo);
    // }
    return formattedDates;
  }
}
