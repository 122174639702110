export default [
  {
    component: 'div',
    class: 'flex',
    children: [
      {
        type: 'text',
        name: 'name',
        label: 'Full Name',
        placeholder: 'Full Name',
        validation: 'required',
        'validation-name': 'Name',
      },
      {
        type: 'email',
        name: 'email',
        label: 'Email Address',
        placeholder: 'Email Address',
        validation: 'required',
        'validation-name': 'Email',
      },
      {
        type: 'submit',
        label: 'Invite',
        children: [
          {
            component: 'font-awesome-icon',
            icon: 'envelope',
          },
          {
            component: 'span',
            children: [
              {
                type: 'span',
                label: 'Invite',
              },
            ],
          },
        ],
      },
    ],
  },
];
