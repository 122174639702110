<route>
{
  "meta": {
    "breadcrumb": "Dashboard"
  }
}
</route>
<template>
  <div class="guest-dashboard">
    <GuestLandingPage />
  </div>
</template>

<script>
// @ is an alias to /src
import GuestLandingPage from '@/modules/guests/pages/GuestLandingPage';

export default {
  name: 'guests',
  components: {
    GuestLandingPage,
  },
  layout: 'AppMain',
};
</script>
