
import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';
import checkInIcon from '@/assets/fonts/Check-in-icon.svg';
import checkCircle from '@/components/svg/check-circle.svg';
import dayjs from 'dayjs';
import { guestModule } from '@/store/modules/guest.module';
import inviteGuestSchema from '../schema/invite-guest';

interface GuestInvite {
  name?: string;
  email?: string;
}

@Component({
  components: {
    checkInIcon,
    checkCircle,
  },
})
export default class GuestProfileGuestCard extends Vue {
  @Ref('expand') bookingTxt: any;

  inviteBtn = 'invite';

  viewFlag = false;

  guestArr: GuestInvite[] = [];

  guestSync: GuestInvite = {};

  inviteGuestSchema = inviteGuestSchema;

  @Prop({ type: Object, default: '' }) guest!: any;

  get minorsLength() {
    return this.guest?.minors?.length || null;
  }

  get accommodation() {
    return this.booking.accommodation;
  }

  get booking() {
    return guestModule.booking;
  }

  get days(): number {
    const checkOut = dayjs(this.booking.checkOutDate, 'DD MM YYYY');
    const checkIn = dayjs(this.booking.checkInDate, 'DD MM YYYY');
    return checkOut.diff(checkIn, 'day');
  }

  async inviteGuest() {
    this.guest.status = 'PENDINGCHECKIN';
    await guestModule.updateGuest(this.guest);
    this.inviteBtn = 'Invited';
  }

  viewMore(): void {
    this.viewFlag = !this.viewFlag;
  }

  get accommodationLogo() {
    return this.accommodation?.logo;
  }

  // test() {
  //   const height = this.$refs.expand.clientHeight;
  //   this.$refs.expand.style.height = `${height + 38}px`;
  //   this.guestArr.push(this.guestSync);
  // }
}
